<template>
    <div class="main__container main__padding">
        <h2>Impressum</h2>
        <div class="imprint-box">
            <div class="header">Angaben gemäß § 5 TMG</div>
            <div>Industrie- und Handelskammer Mittlerer Niederrhein</div>
            <div>Nordwall 39</div>
            <div>
                47798 Krefeld
            </div>
            <div>
                <br />
                Vertreten durch:
            </div>
            <div>
                Elmar te Neues
            </div>
            <div>Jürgen Steinmetz</div>
            <div class="header mt-40">
                Kontakt
            </div>
            <div>Telefon: +49 (0) 2151 635 – 0</div>
            <div>Telefax: +49 (0) 2151 635 – 338</div>
            <div>E-Mail: ihk@mittlerer-niederrhein.ihk.de</div>
            <div class="header mt-40">
                Redaktionell Verantwortlicher
            </div>
            <div>Thomas Anft</div>
            <div>Bismarckstr. 109</div>
            <div>41061 Mönchengladbach</div>
            <div class="header mt-40">
                Haftung für Inhalte
            </div>
            <div>
                Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene
                Inhalte auf diesen Seiten nach den allgemeinen Gesetzen
                verantwortlich. Nach §§ 8 bis 10 TMG sind wir als
                Diensteanbieter jedoch nicht verpflichtet, übermittelte oder
                gespeicherte fremde Informationen zu überwachen oder nach
                Umständen zu forschen, die auf eine rechtswidrige Tätigkeit
                hinweisen. Verpflichtungen zur Entfernung oder Sperrung der
                Nutzung von Informationen nach den allgemeinen Gesetzen bleiben
                hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst
                ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung
                möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen
                werden wir diese Inhalte umgehend entfernen.
            </div>
            <div class="header mt-40">
                Haftung für Links
            </div>
            <div>
                Unser Angebot enthält Links zu externen Websites Dritter, auf
                deren Inhalte wir keinen Einfluss haben. Deshalb können wir für
                diese fremden Inhalte auch keine Gewähr übernehmen. Für die
                Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter
                oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten
                wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße
                überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der
                Verlinkung nicht erkennbar. Eine permanente inhaltliche
                Kontrolle der verlinkten Seiten ist jedoch ohne konkrete
                Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei
                Bekanntwerden von Rechtsverletzungen werden wir derartige Links
                umgehend entfernen.
            </div>
            <div class="header mt-40">
                Urheberrecht
            </div>
            <div>
                Die durch die Seitenbetreiber erstellten Inhalte und Werke auf
                diesen Seiten unterliegen dem deutschen Urheberrecht. Die
                Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
                Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der
                schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
                Downloads und Kopien dieser Seite sind nur für den privaten,
                nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf
                dieser Seite nicht vom Betreiber erstellt wurden, werden die
                Urheberrechte Dritter beachtet. Insbesondere werden Inhalte
                Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine
                Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
                entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen
                werden wir derartige Inhalte umgehend entfernen.
            </div>
            <div class="mt-40">Quelle:</div>
            <div>https://www.e-recht24.de/impressum-generator.html</div>
        </div>
        <Footer />
    </div>
</template>

<script>
import Footer from "../../components/Footer.vue";
export default {
    components: { Footer },
    name: "Imprint",
    data() {
        return {};
    },
    methods: {},
    created() {},
    computed: {},
};
</script>
