<template>
	<div class="main__container main__padding column__center">
		<div class="imprint-box  ">
			<h2>Für Schüler</h2>

			<div class="header-3">
				Bei der It’s your Match findet ihr passende Unternehmen in eurer Region.
			</div>
			<div>
				Wählt einfach ein zu eurem Berufsfeld passendes Unternehmen bzw. Angebot
				aus und erhaltet weitere Informationen zu den jeweiligen Ausstellern
				bzw. Unternehmen. Das Herzstück der It’s your Match ist der persönliche
				Kontakt von euch zu Aussteller. Über die Aussteller Profile habt ihr die
				Möglichkeit Termine online mit den Ausstellern zu buchen. Diese sind
				immer 15 Minuten lang und Ihr habt die Wahl aus Chat, Telefon und
				Videocall.<br />
				Um so einen Gesprächstermin zu buchen und eure Termine zu verwalten
				benötigt Ihr ein Schülerprofil. <br />Dieses legt ihr hier an:
			</div>
			<div class="row__start mt-20">
				<a
					class="button button--big mr-20"
					@click="
						() => {
							'Register' != $route.name
								? $router.push({ name: 'Register' })
								: '';
						}
					"
				>
					<p class="text__white">Als Schüler registrieren</p>
				</a>
				<a
					class="button   button--reverse"
					@click="
						() => {
							'Login' != $route.name ? $router.push({ name: 'Login' }) : '';
						}
					"
				>
					<p class="text__primary">Schüler Login</p>
				</a>
			</div>
			<Footer class="w-100" />
		</div>
	</div>
</template>

<script>
import Footer from '../components/Footer.vue';
export default {
	components: { Footer },
	name: 'ForStudents',
	data() {
		return {};
	},
	methods: {},
	created() {},
	computed: {},
};
</script>
