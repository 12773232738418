<template>
    <div class="main__container main__padding" v-if="!token">
        <div class="loginBox column__center">
            <LogoSvg />
            <h1 class="text__semibold mt-25 mb-40">
                Passwort zurücksetzen
            </h1>
            <p class="text__center text__grey mb-30">
                Bitte geben Sie ihr neues Passwort ein und wiederholen dieses.
            </p>
            <form>
                <div class="searchbox row__start mb-25">
                    <input
                        type="password"
                        @keyup="checkPasswordStrength"
                        v-model="userPassword"
                        placeholder="Passwort"
                    />
                    <p class="error__message" v-if="passwordError">
                        {{ passwordError }}
                    </p>
                </div>
                <div class="searchbox row__start mb-25">
                    <input
                        type="password"
                        @keyup="checkPasswordStrength"
                        v-model="userConfirmPassword"
                        placeholder="Passwort wiederholen"
                    />
                    <p class="error__message" v-if="confirmPasswordError">
                        {{ confirmPasswordError }}
                    </p>
                </div>
            </form>

            <div class="button mt-25" @click="prepareSubmit">
                <p class="text__white">Speichern</p>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
    name: "Reset",
    data() {
        return {
            userPassword: "",
            userConfirmPassword: "",
            passwordError: "",
            confirmPasswordError: "",
            passwordValid: null,
        };
    },
    methods: {
        ...mapActions(["resetPassword"]),
        removeErrorMessages() {
            this.userError = "";
        },
        async prepareSubmit() {
            const password = this.userPassword;
            const password_confirmation = this.userConfirmPassword;
            const email = this.$router.history.current.query.email;
            const token = this.$router.history.current.query.token;
            await this.resetPassword({
                email,
                password,
                password_confirmation,
                token,
            });

            this.userPassword = "";
            this.userConfirmPassword = "";
        },
        checkPasswordStrength() {
            const password = this.userPassword;
            this.passwordError = "";
            this.confirmPasswordError = "";
            this.passwordValid = false;
            if (!/[A-Z]/.test(password)) {
                this.passwordError = "Mindestens ein Großbuchstaben.";
                return;
            }
            if (!/[a-z]/.test(password)) {
                this.passwordError = "Mindestens ein Kleinbuchstaben.";
                return;
            }
            if (!/[0-9]/.test(password)) {
                this.passwordError = "Mindestens eine Zahl.";
                return;
            }
            if (!/[^A-Za-z0-9]/.test(password)) {
                this.passwordError = "Mindestens ein Sonderzeichen.";
                return;
            }
            if (password.length <= 5) {
                this.passwordError = "Mindestens 6 Zeichen.";
                return;
            }
            this.passwordValid = true;
            this.confirmPasswordValid = false;

            if (this.userPassword === this.userConfirmPassword) {
                this.confirmPasswordValid = true;
            } else {
                this.confirmPasswordError = "Passwörter stimmen nicht überein.";
            }
        },
    },
    computed: {
        ...mapGetters(["token"]),
    },
    created() {
        if (this.token) {
            this.$router.push({
                name: "Home",
            });
        }
    },
};
</script>

<style></style>
