<template>
	<a
		target="_blank"
		@click="goToCompanyPage(company)"
		style="cursor:pointer;"
	>
		<div class="companyContainer">
			<img
				:src="
					company.image != null
						? company.image
						: './img/images/placeholder.png'
				"
				alt=""
			/>

			<div style="height: 48px; display: table">
				<p class="companyNameContainer">
					{{ trimmedCompanyName() }}
				</p>
			</div>
		</div>
	</a>
</template>
<script>
	export default {
		props: ["company"],
		data() {
			return {};
		},
		methods: {
			goToCompanyPage(company) {
				var company_name = company.name.trim().replaceAll(" ", "-");
				var url = "/company/" + company.id + "/" + company_name;
				this.$router.push(url);
			},
			trimmedCompanyName() {
				return this.company.name.trim();
			},
		},
	};
</script>

<style scoped>
	.companyContainer {
		display: flex;
		min-width: 300px;
		min-height: 50px;
		width: 23.5vw;
		height: 6vh;
		margin-right: 10px;
		margin-bottom: 10px;
		border: 1px solid rgb(165, 165, 165);
		border-radius: 10px;
		background: white;
	}
	.companyNameContainer {
		text-align: left;
		vertical-align: middle;
		display: table-cell;
		font-size: 15px;
		margin-left: 2px;
	}

	img {
		padding: 5px;
		min-width: 50px;
		width: 5vw;
		object-fit: contain;
	}

	@media only screen and (max-width: 768px) {
		.companyContainer {
			display: flex;
			min-width: 300px;
			min-height: 50px;
			width: 90vw;
			height: 6vh;
			margin-right: 10px;
			margin-bottom: 10px;
			border: 1px solid rgb(165, 165, 165);
			border-radius: 10px;
			background: white;
		}

		.companyNameContainer {
			text-align: left;
			vertical-align: middle;
			display: table-cell;
			font-size: 15px;
			margin-left: 2px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			max-width: 60vw;
		}
	}
</style>
