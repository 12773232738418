<template>
    <div class="main__container main__padding">
        <h2>Datenschutz&shy;erkl&auml;rung</h2>
        <div class="imprint-box">
            <div class="header-1">1. Datenschutz auf einen Blick</div>
            <div class="header-3">Allgemeine Hinweise</div>
            <div>
                Die folgenden Hinweise geben einen einfachen &Uuml;berblick
                dar&uuml;ber, was mit Ihren personenbezogenen Daten passiert,
                wenn Sie diese Website besuchen. Personenbezogene Daten sind
                alle Daten, mit denen Sie pers&ouml;nlich identifiziert werden
                k&ouml;nnen. Ausf&uuml;hrliche Informationen zum Thema
                Datenschutz entnehmen Sie unserer unter diesem Text
                aufgef&uuml;hrten Datenschutzerkl&auml;rung.
            </div>
            <div class="header-3">Datenerfassung auf dieser Website</div>
            <div class="header-4">
                Wer ist verantwortlich f&uuml;r die Datenerfassung auf dieser
                Website?
            </div>
            <div>
                Die Datenverarbeitung auf dieser Website erfolgt durch den
                Websitebetreiber. Dessen Kontaktdaten k&ouml;nnen Sie dem
                Abschnitt &bdquo;Hinweis zur Verantwortlichen Stelle&ldquo; in
                dieser Datenschutzerkl&auml;rung entnehmen.
            </div>
            <div class="header-4">Wie erfassen wir Ihre Daten?</div>
            <div>
                Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese
                mitteilen. Hierbei kann es sich z.&nbsp;B. um Daten handeln, die
                Sie in ein Kontaktformular eingeben.
            </div>
            <div>
                Andere Daten werden automatisch oder nach Ihrer Einwilligung
                beim Besuch der Website durch unsere IT-Systeme erfasst. Das
                sind vor allem technische Daten (z.&nbsp;B. Internetbrowser,
                Betriebssystem oder Uhrzeit des Seitenaufrufs). Die Erfassung
                dieser Daten erfolgt automatisch, sobald Sie diese Website
                betreten.
            </div>
            <div class="header-4">Wof&uuml;r nutzen wir Ihre Daten?</div>
            <div>
                Ein Teil der Daten wird erhoben, um eine fehlerfreie
                Bereitstellung der Website zu gew&auml;hrleisten. Andere Daten
                k&ouml;nnen zur Analyse Ihres Nutzerverhaltens verwendet werden.
            </div>
            <div class="header-4">
                Welche Rechte haben Sie bez&uuml;glich Ihrer Daten?
            </div>
            <div>
                Sie haben jederzeit das Recht, unentgeltlich Auskunft &uuml;ber
                Herkunft, Empf&auml;nger und Zweck Ihrer gespeicherten
                personenbezogenen Daten zu erhalten. Sie haben au&szlig;erdem
                ein Recht, die Berichtigung oder L&ouml;schung dieser Daten zu
                verlangen. Wenn Sie eine Einwilligung zur Datenverarbeitung
                erteilt haben, k&ouml;nnen Sie diese Einwilligung jederzeit
                f&uuml;r die Zukunft widerrufen. Au&szlig;erdem haben Sie das
                Recht, unter bestimmten Umst&auml;nden die Einschr&auml;nkung
                der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Des
                Weiteren steht Ihnen ein Beschwerderecht bei der
                zust&auml;ndigen Aufsichtsbeh&ouml;rde zu.
            </div>
            <div>
                Hierzu sowie zu weiteren Fragen zum Thema Datenschutz
                k&ouml;nnen Sie sich jederzeit an uns wenden.
            </div>
            <div class="header-1">
                2. Hosting und Content Delivery Networks (CDN)
            </div>
            <div class="header-3">Externes Hosting</div>
            <div>
                Diese Website wird bei einem externen Dienstleister gehostet
                (Hoster). Die personenbezogenen Daten, die auf dieser Website
                erfasst werden, werden auf den Servern des Hosters gespeichert.
                Hierbei kann es sich v. a. um IP-Adressen, Kontaktanfragen,
                Meta- und Kommunikationsdaten, Vertragsdaten, Kontaktdaten,
                Namen, Websitezugriffe und sonstige Daten, die &uuml;ber eine
                Website generiert werden, handeln.
            </div>
            <div>
                Der Einsatz des Hosters erfolgt zum Zwecke der
                Vertragserf&uuml;llung gegen&uuml;ber unseren potenziellen und
                bestehenden Kunden (Art. 6 Abs. 1 lit. b DSGVO) und im Interesse
                einer sicheren, schnellen und effizienten Bereitstellung unseres
                Online-Angebots durch einen professionellen Anbieter (Art. 6
                Abs. 1 lit. f DSGVO).
            </div>
            <div>
                Unser Hoster wird Ihre Daten nur insoweit verarbeiten, wie dies
                zur Erf&uuml;llung seiner Leistungspflichten erforderlich ist
                und unsere Weisungen in Bezug auf diese Daten befolgen.
            </div>
            <div>Wir setzen folgenden Hoster ein:</div>
            <div>
                opwoco products GmbH<br />
                Kircheninsel 3<br />
                48599 Gronau
            </div>
            <div class="header-4">
                Abschluss eines Vertrages &uuml;ber Auftragsverarbeitung
            </div>
            <div>
                Um die datenschutzkonforme Verarbeitung zu gew&auml;hrleisten,
                haben wir einen Vertrag &uuml;ber Auftragsverarbeitung mit
                unserem Hoster geschlossen.
            </div>
            <div class="header-1">
                3. Allgemeine Hinweise und Pflicht&shy;informationen
            </div>
            <div class="header-3">Datenschutz</div>
            <div>
                Die Betreiber dieser Seiten nehmen den Schutz Ihrer
                pers&ouml;nlichen Daten sehr ernst. Wir behandeln Ihre
                personenbezogenen Daten vertraulich und entsprechend den
                gesetzlichen Datenschutzvorschriften sowie dieser
                Datenschutzerkl&auml;rung.
            </div>
            <div>
                Wenn Sie diese Website benutzen, werden verschiedene
                personenbezogene Daten erhoben. Personenbezogene Daten sind
                Daten, mit denen Sie pers&ouml;nlich identifiziert werden
                k&ouml;nnen. Die vorliegende Datenschutzerkl&auml;rung
                erl&auml;utert, welche Daten wir erheben und wof&uuml;r wir sie
                nutzen. Sie erl&auml;utert auch, wie und zu welchem Zweck das
                geschieht.
            </div>
            <div>
                Wir weisen darauf hin, dass die Daten&uuml;bertragung im
                Internet (z.&nbsp;B. bei der Kommunikation per E-Mail)
                Sicherheitsl&uuml;cken aufweisen kann. Ein l&uuml;ckenloser
                Schutz der Daten vor dem Zugriff durch Dritte ist nicht
                m&ouml;glich.
            </div>
            <div class="header-3">Hinweis zur verantwortlichen Stelle</div>
            <div>
                Die verantwortliche Stelle f&uuml;r die Datenverarbeitung auf
                dieser Website ist:
            </div>
            <div>
                Industrie- und Handelskammer Mittlerer Niederrhein<br />
                Nordwall 39<br />
                47798 Krefeld
            </div>

            <div>
                Telefon: +49 (0) 2151 635 &ndash; 0<br />
                E-Mail: ihk@mittlerer-niederrhein.ihk.de
            </div>
            <div>
                Verantwortliche Stelle ist die nat&uuml;rliche oder juristische
                Person, die allein oder gemeinsam mit anderen &uuml;ber die
                Zwecke und Mittel der Verarbeitung von personenbezogenen Daten
                (z.&nbsp;B. Namen, E-Mail-Adressen o. &Auml;.) entscheidet.
            </div>

            <div class="header-3">Speicherdauer</div>
            <div>
                Soweit innerhalb dieser Datenschutzerkl&auml;rung keine
                speziellere Speicherdauer genannt wurde, verbleiben Ihre
                personenbezogenen Daten bei uns, bis der Zweck f&uuml;r die
                Datenverarbeitung entf&auml;llt. Wenn Sie ein berechtigtes
                L&ouml;schersuchen geltend machen oder eine Einwilligung zur
                Datenverarbeitung widerrufen, werden Ihre Daten gel&ouml;scht,
                sofern wir keine anderen rechtlich zul&auml;ssigen Gr&uuml;nde
                f&uuml;r die Speicherung Ihrer personenbezogenen Daten haben
                (z.&nbsp;B. steuer- oder handelsrechtliche
                Aufbewahrungsfristen); im letztgenannten Fall erfolgt die
                L&ouml;schung nach Fortfall dieser Gr&uuml;nde.
            </div>
            <div class="header-3">
                Hinweis zur Datenweitergabe in die USA und sonstige Drittstaaten
            </div>
            <div>
                Wir verwenden unter anderem Tools von Unternehmen mit Sitz in
                den USA oder sonstigen datenschutzrechtlich nicht sicheren
                Drittstaaten. Wenn diese Tools aktiv sind, k&ouml;nnen Ihre
                personenbezogene Daten in diese Drittstaaten &uuml;bertragen und
                dort verarbeitet werden. Wir weisen darauf hin, dass in diesen
                L&auml;ndern kein mit der EU vergleichbares Datenschutzniveau
                garantiert werden kann. Beispielsweise sind US-Unternehmen dazu
                verpflichtet, personenbezogene Daten an Sicherheitsbeh&ouml;rden
                herauszugeben, ohne dass Sie als Betroffener hiergegen
                gerichtlich vorgehen k&ouml;nnten. Es kann daher nicht
                ausgeschlossen werden, dass US-Beh&ouml;rden (z.&nbsp;B.
                Geheimdienste) Ihre auf US-Servern befindlichen Daten zu
                &Uuml;berwachungszwecken verarbeiten, auswerten und dauerhaft
                speichern. Wir haben auf diese Verarbeitungst&auml;tigkeiten
                keinen Einfluss.
            </div>
            <div class="header-3">
                Widerruf Ihrer Einwilligung zur Datenverarbeitung
            </div>
            <div>
                Viele Datenverarbeitungsvorg&auml;nge sind nur mit Ihrer
                ausdr&uuml;cklichen Einwilligung m&ouml;glich. Sie k&ouml;nnen
                eine bereits erteilte Einwilligung jederzeit widerrufen. Die
                Rechtm&auml;&szlig;igkeit der bis zum Widerruf erfolgten
                Datenverarbeitung bleibt vom Widerruf unber&uuml;hrt.
            </div>
            <div class="header-3">
                Widerspruchsrecht gegen die Datenerhebung in besonderen
                F&auml;llen sowie gegen Direktwerbung (Art. 21 DSGVO)
            </div>
            <div>
                WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT.
                E ODER F DSGVO ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS
                GR&Uuml;NDEN, DIE SICH AUS IHRER BESONDEREN SITUATION ERGEBEN,
                GEGEN DIE VERARBEITUNG IHRER PERSONENBEZOGENEN DATEN WIDERSPRUCH
                EINZULEGEN; DIES GILT AUCH F&Uuml;R EIN AUF DIESE BESTIMMUNGEN
                GEST&Uuml;TZTES PROFILING. DIE JEWEILIGE RECHTSGRUNDLAGE, AUF
                DENEN EINE VERARBEITUNG BERUHT, ENTNEHMEN SIE DIESER
                DATENSCHUTZERKL&Auml;RUNG. WENN SIE WIDERSPRUCH EINLEGEN, WERDEN
                WIR IHRE BETROFFENEN PERSONENBEZOGENEN DATEN NICHT MEHR
                VERARBEITEN, ES SEI DENN, WIR K&Ouml;NNEN ZWINGENDE
                SCHUTZW&Uuml;RDIGE GR&Uuml;NDE F&Uuml;R DIE VERARBEITUNG
                NACHWEISEN, DIE IHRE INTERESSEN, RECHTE UND FREIHEITEN
                &Uuml;BERWIEGEN ODER DIE VERARBEITUNG DIENT DER GELTENDMACHUNG,
                AUS&Uuml;BUNG ODER VERTEIDIGUNG VON RECHTSANSPR&Uuml;CHEN
                (WIDERSPRUCH NACH ART. 21 ABS. 1 DSGVO).
            </div>
            <div>
                WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM
                DIREKTWERBUNG ZU BETREIBEN, SO HABEN SIE DAS RECHT, JEDERZEIT
                WIDERSPRUCH GEGEN DIE VERARBEITUNG SIE BETREFFENDER
                PERSONENBEZOGENER DATEN ZUM ZWECKE DERARTIGER WERBUNG
                EINZULEGEN; DIES GILT AUCH F&Uuml;R DAS PROFILING, SOWEIT ES MIT
                SOLCHER DIREKTWERBUNG IN VERBINDUNG STEHT. WENN SIE
                WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN ANSCHLIESSEND
                NICHT MEHR ZUM ZWECKE DER DIREKTWERBUNG VERWENDET (WIDERSPRUCH
                NACH ART. 21 ABS. 2 DSGVO).
            </div>
            <div class="header-3">
                Beschwerde&shy;recht bei der zust&auml;ndigen
                Aufsichts&shy;beh&ouml;rde
            </div>
            <div>
                Im Falle von Verst&ouml;&szlig;en gegen die DSGVO steht den
                Betroffenen ein Beschwerderecht bei einer Aufsichtsbeh&ouml;rde,
                insbesondere in dem Mitgliedstaat ihres gew&ouml;hnlichen
                Aufenthalts, ihres Arbeitsplatzes oder des Orts des
                mutma&szlig;lichen Versto&szlig;es zu. Das Beschwerderecht
                besteht unbeschadet anderweitiger verwaltungsrechtlicher oder
                gerichtlicher Rechtsbehelfe.
            </div>
            <div class="header-3">
                Recht auf Daten&shy;&uuml;bertrag&shy;barkeit
            </div>
            <div>
                Sie haben das Recht, Daten, die wir auf Grundlage Ihrer
                Einwilligung oder in Erf&uuml;llung eines Vertrags automatisiert
                verarbeiten, an sich oder an einen Dritten in einem
                g&auml;ngigen, maschinenlesbaren Format aush&auml;ndigen zu
                lassen. Sofern Sie die direkte &Uuml;bertragung der Daten an
                einen anderen Verantwortlichen verlangen, erfolgt dies nur,
                soweit es technisch machbar ist.
            </div>
            <div class="header-3">SSL- bzw. TLS-Verschl&uuml;sselung</div>
            <div>
                Diese Seite nutzt aus Sicherheitsgr&uuml;nden und zum Schutz der
                &Uuml;bertragung vertraulicher Inhalte, wie zum Beispiel
                Bestellungen oder Anfragen, die Sie an uns als Seitenbetreiber
                senden, eine SSL- bzw. TLS-Verschl&uuml;sselung. Eine
                verschl&uuml;sselte Verbindung erkennen Sie daran, dass die
                Adresszeile des Browsers von &bdquo;http://&ldquo; auf
                &bdquo;https://&ldquo; wechselt und an dem Schloss-Symbol in
                Ihrer Browserzeile.
            </div>
            <div>
                Wenn die SSL- bzw. TLS-Verschl&uuml;sselung aktiviert ist,
                k&ouml;nnen die Daten, die Sie an uns &uuml;bermitteln, nicht
                von Dritten mitgelesen werden.
            </div>
            <div class="header-3">Auskunft, L&ouml;schung und Berichtigung</div>
            <div>
                Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen
                jederzeit das Recht auf unentgeltliche Auskunft &uuml;ber Ihre
                gespeicherten personenbezogenen Daten, deren Herkunft und
                Empf&auml;nger und den Zweck der Datenverarbeitung und ggf. ein
                Recht auf Berichtigung oder L&ouml;schung dieser Daten. Hierzu
                sowie zu weiteren Fragen zum Thema personenbezogene Daten
                k&ouml;nnen Sie sich jederzeit an uns wenden.
            </div>
            <div class="header-3">
                Recht auf Einschr&auml;nkung der Verarbeitung
            </div>
            <div>
                Sie haben das Recht, die Einschr&auml;nkung der Verarbeitung
                Ihrer personenbezogenen Daten zu verlangen. Hierzu k&ouml;nnen
                Sie sich jederzeit an uns wenden. Das Recht auf
                Einschr&auml;nkung der Verarbeitung besteht in folgenden
                F&auml;llen:
            </div>
            <ul>
                <li>
                    Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten
                    personenbezogenen Daten bestreiten, ben&ouml;tigen wir in
                    der Regel Zeit, um dies zu &uuml;berpr&uuml;fen. F&uuml;r
                    die Dauer der Pr&uuml;fung haben Sie das Recht, die
                    Einschr&auml;nkung der Verarbeitung Ihrer personenbezogenen
                    Daten zu verlangen.
                </li>
                <li>
                    Wenn die Verarbeitung Ihrer personenbezogenen Daten
                    unrechtm&auml;&szlig;ig geschah/geschieht, k&ouml;nnen Sie
                    statt der L&ouml;schung die Einschr&auml;nkung der
                    Datenverarbeitung verlangen.
                </li>
                <li>
                    Wenn wir Ihre personenbezogenen Daten nicht mehr
                    ben&ouml;tigen, Sie sie jedoch zur Aus&uuml;bung,
                    Verteidigung oder Geltendmachung von Rechtsanspr&uuml;chen
                    ben&ouml;tigen, haben Sie das Recht, statt der L&ouml;schung
                    die Einschr&auml;nkung der Verarbeitung Ihrer
                    personenbezogenen Daten zu verlangen.
                </li>
                <li>
                    Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO
                    eingelegt haben, muss eine Abw&auml;gung zwischen Ihren und
                    unseren Interessen vorgenommen werden. Solange noch nicht
                    feststeht, wessen Interessen &uuml;berwiegen, haben Sie das
                    Recht, die Einschr&auml;nkung der Verarbeitung Ihrer
                    personenbezogenen Daten zu verlangen.
                </li>
            </ul>
            <div>
                Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten
                eingeschr&auml;nkt haben, d&uuml;rfen diese Daten &ndash; von
                ihrer Speicherung abgesehen &ndash; nur mit Ihrer Einwilligung
                oder zur Geltendmachung, Aus&uuml;bung oder Verteidigung von
                Rechtsanspr&uuml;chen oder zum Schutz der Rechte einer anderen
                nat&uuml;rlichen oder juristischen Person oder aus Gr&uuml;nden
                eines wichtigen &ouml;ffentlichen Interesses der
                Europ&auml;ischen Union oder eines Mitgliedstaats verarbeitet
                werden.
            </div>
            <div class="header-1">4. Datenerfassung auf dieser Website</div>
            <div class="header-3">Cookies</div>
            <div>
                Unsere Internetseiten verwenden so genannte
                &bdquo;Cookies&ldquo;. Cookies sind kleine Textdateien und
                richten auf Ihrem Endger&auml;t keinen Schaden an. Sie werden
                entweder vor&uuml;bergehend f&uuml;r die Dauer einer Sitzung
                (Session-Cookies) oder dauerhaft (permanente Cookies) auf Ihrem
                Endger&auml;t gespeichert. Session-Cookies werden nach Ende
                Ihres Besuchs automatisch gel&ouml;scht. Permanente Cookies
                bleiben auf Ihrem Endger&auml;t gespeichert, bis Sie diese
                selbst l&ouml;schen&nbsp;oder eine automatische L&ouml;schung
                durch Ihren Webbrowser erfolgt.
            </div>
            <div>
                Teilweise k&ouml;nnen auch Cookies von Drittunternehmen auf
                Ihrem Endger&auml;t gespeichert werden, wenn Sie unsere Seite
                betreten (Third-Party-Cookies). Diese erm&ouml;glichen uns oder
                Ihnen die Nutzung bestimmter Dienstleistungen des
                Drittunternehmens (z.&nbsp;B. Cookies zur Abwicklung von
                Zahlungsdienstleistungen).
            </div>
            <div>
                Cookies haben verschiedene Funktionen. Zahlreiche Cookies sind
                technisch notwendig, da bestimmte Websitefunktionen ohne diese
                nicht funktionieren w&uuml;rden (z.&nbsp;B. die
                Warenkorbfunktion oder die Anzeige von Videos). Andere Cookies
                dienen dazu, das Nutzerverhalten auszuwerten&nbsp;oder Werbung
                anzuzeigen.
            </div>
            <div>
                Cookies, die zur Durchf&uuml;hrung des elektronischen
                Kommunikationsvorgangs (notwendige Cookies) oder zur
                Bereitstellung bestimmter, von Ihnen erw&uuml;nschter Funktionen
                (funktionale Cookies, z.&nbsp;B. f&uuml;r die Warenkorbfunktion)
                oder zur Optimierung der Website (z.&nbsp;B. Cookies zur Messung
                des Webpublikums) erforderlich sind, werden auf Grundlage von
                Art. 6 Abs. 1 lit. f DSGVO gespeichert, sofern keine andere
                Rechtsgrundlage angegeben wird. Der Websitebetreiber hat ein
                berechtigtes Interesse an der Speicherung von Cookies zur
                technisch fehlerfreien und optimierten Bereitstellung seiner
                Dienste. Sofern eine Einwilligung zur Speicherung von Cookies
                abgefragt wurde, erfolgt die Speicherung der betreffenden
                Cookies ausschlie&szlig;lich auf Grundlage dieser Einwilligung
                (Art. 6 Abs. 1 lit. a DSGVO); die Einwilligung ist jederzeit
                widerrufbar.
            </div>
            <div>
                Sie k&ouml;nnen Ihren Browser so einstellen, dass Sie &uuml;ber
                das Setzen von Cookies informiert werden und Cookies nur im
                Einzelfall erlauben, die Annahme von Cookies f&uuml;r bestimmte
                F&auml;lle oder generell ausschlie&szlig;en sowie das
                automatische L&ouml;schen der Cookies beim Schlie&szlig;en des
                Browsers aktivieren. Bei der Deaktivierung von Cookies kann die
                Funktionalit&auml;t dieser Website eingeschr&auml;nkt sein.
            </div>
            <div>
                Soweit Cookies von Drittunternehmen oder zu Analysezwecken
                eingesetzt werden, werden wir Sie hier&uuml;ber im Rahmen dieser
                Datenschutzerkl&auml;rung gesondert informieren und ggf. eine
                Einwilligung abfragen.
            </div>
            <div class="header-3">Anfrage per E-Mail, Telefon oder Telefax</div>
            <div>
                Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird
                Ihre Anfrage inklusive aller daraus hervorgehenden
                personenbezogenen Daten (Name, Anfrage) zum Zwecke der
                Bearbeitung Ihres Anliegens bei uns gespeichert und verarbeitet.
                Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.
            </div>
            <div>
                Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6
                Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der Erf&uuml;llung
                eines Vertrags zusammenh&auml;ngt oder zur Durchf&uuml;hrung
                vorvertraglicher Ma&szlig;nahmen erforderlich ist. In allen
                &uuml;brigen F&auml;llen beruht die Verarbeitung auf unserem
                berechtigten Interesse an der effektiven Bearbeitung der an uns
                gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer
                Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt
                wurde.
            </div>
            <div>
                Die von Ihnen an uns per Kontaktanfragen &uuml;bersandten Daten
                verbleiben bei uns, bis Sie uns zur L&ouml;schung auffordern,
                Ihre Einwilligung zur Speicherung widerrufen oder der Zweck
                f&uuml;r die Datenspeicherung entf&auml;llt (z.&nbsp;B. nach
                abgeschlossener Bearbeitung Ihres Anliegens). Zwingende
                gesetzliche Bestimmungen &ndash; insbesondere gesetzliche
                Aufbewahrungsfristen &ndash; bleiben unber&uuml;hrt.
            </div>
            <div class="header-1">5. Plugins und Tools</div>
            <div class="header-3">Google Maps</div>
            <div>
                Diese Seite nutzt den Kartendienst Google Maps. Anbieter ist die
                Google Ireland Limited (&bdquo;Google&ldquo;), Gordon House,
                Barrow Street, Dublin 4, Irland.
            </div>
            <div>
                Zur Nutzung der Funktionen von Google Maps ist es notwendig,
                Ihre IP-Adresse zu speichern. Diese Informationen werden in der
                Regel an einen Server von Google in den USA &uuml;bertragen und
                dort gespeichert. Der Anbieter dieser Seite hat keinen Einfluss
                auf diese Daten&uuml;bertragung. Wenn Google Maps aktiviert ist,
                kann Google zum Zwecke der einheitlichen Darstellung der
                Schriftarten Google Web Fonts verwenden. Beim Aufruf von Google
                Maps l&auml;dt Ihr Browser die ben&ouml;tigten Web Fonts in
                ihren Browsercache, um Texte und Schriftarten korrekt
                anzuzeigen.
            </div>
            <div>
                Die Nutzung von Google Maps erfolgt im Interesse einer
                ansprechenden Darstellung unserer Online-Angebote und an einer
                leichten Auffindbarkeit der von uns auf der Website angegebenen
                Orte. Dies stellt ein berechtigtes Interesse im Sinne von Art. 6
                Abs. 1 lit. f DSGVO dar. Sofern eine entsprechende Einwilligung
                abgefragt wurde, erfolgt die Verarbeitung ausschlie&szlig;lich
                auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung
                ist jederzeit widerrufbar.
            </div>
            <div>
                Die Daten&uuml;bertragung in die USA wird auf die
                Standardvertragsklauseln der EU-Kommission gest&uuml;tzt.
                Details finden Sie hier:
                <a
                    href="https://privacy.google.com/businesses/gdprcontrollerterms/"
                    target="_blank"
                    rel="noopener noreferrer"
                    >https://privacy.google.com/businesses/gdprcontrollerterms/</a
                >
                und
                <a
                    href="https://privacy.google.com/businesses/gdprcontrollerterms/sccs/"
                    target="_blank"
                    rel="noopener noreferrer"
                    >https://privacy.google.com/businesses/gdprcontrollerterms/sccs/</a
                >.
            </div>
            <div>
                Mehr Informationen zum Umgang mit Nutzerdaten finden Sie in der
                Datenschutzerkl&auml;rung von Google:
                <a
                    href="https://policies.google.com/privacy?hl=de"
                    target="_blank"
                    rel="noopener noreferrer"
                    >https://policies.google.com/privacy?hl=de</a
                >.
            </div>
            <div>
                Quelle:
                <a href="https://www.datenschutzerklaerung.de"
                    >https://www.datenschutzerklaerung.de</a
                >
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import Footer from "../../components/Footer.vue";
export default {
    components: { Footer },
    name: "Privacy",
    data() {
        return {};
    },
    methods: {},
    created() {},
    computed: {},
};
</script>
